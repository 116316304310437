<template>
  <div style="margin: 15px 50px" class="edit_case" v-loading="loading">
    <div style="text-align: center; line-height: 45px; font-size: 18px; font-weight: 800">
      新增/编辑案例
    </div>
    <el-form size="small" ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item label="案例名称:" prop="title">
        <el-input style="width: 280px" v-model="ruleForm.title" placeholder=" "></el-input>
      </el-form-item>
      <el-form-item label="展示排序:" prop="sort">
        <el-input style="width: 280px" v-model.number="ruleForm.sort" placeholder=" "></el-input>
      </el-form-item>
      <!-- <el-form-item prop="space_id" label="云空间">
        <el-select size="small" v-model="ruleForm.space_id" clearable>
          <el-option
            v-for="item in MyCloudList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="案例风格:" prop="case_style">
        <el-select clearable style="width: 280px" v-model="ruleForm.case_style" placeholder=" ">
          <el-option
            v-for="item in styleArray"
            :key="item.s_id"
            :label="item.s_name"
            :value="item.s_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="案例标签:" prop="case_label">
        <div class="mineBtn" style="width: 520px">
          <el-tag
            v-for="tag in ruleForm.case_label"
            :key="tag"
            class="mx-1"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            v-if="inputVisible"
            ref="InputRef"
            style="width: 100px"
            v-model="inputValue"
            class="ml-1 w-20 input-new-tag"
            size="small"
            @keyup.enter="handleInputConfirm"
            @blur="handleInputConfirm"
          ></el-input>
          <el-button v-else class="button-new-tag ml-1" size="small" @click="showInput">
            <i class="el-icon-plus"></i>
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="案例介绍:" prop="richText">
        <!-- <el-input type="textarea" style="width: 530px" v-model="ruleForm.introduce" placeholder=" "></el-input> -->
        <div style="width: 750px" ref="editor"></div>
        <!-- <button @click="btn">btn</button> -->
      </el-form-item>

      <el-form-item label="案例信息:">
        <el-row style="width: 520px">
          <el-col :span="12">
            <el-form-item label="小区:" label-width="50px" prop="msg_xq">
              <el-input v-model="ruleForm.msg_xq" style="width: 170px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="装饰类型:" label-width="80px" prop="msg_zxlx">
              <el-input v-model="ruleForm.msg_zxlx" style="width: 190px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="width: 520px">
          <el-col :span="12">
            <el-form-item label="面积:" label-width="50px" prop="msg_mj">
              <el-input v-model="ruleForm.msg_mj" style="width: 170px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="完工时间:" label-width="80px" prop="msg_wgsj">
              <!-- <el-date-picker style="width: 190px" v-model="ruleForm.msg_wgsj" type="datetime"
                                format="YYYY-MM-DD hh:mm:ss" value-format="YYYY-MM-DD hh:mm:ss" placeholder="选择日期时间">
                            </el-date-picker> -->
              <div style="display: flex">
                <el-input v-model="ruleForm.msg_wgsj" style="width: 170px"></el-input>
                <span style="margin-left: 10px">天</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="width: 520px">
          <el-col :span="12">
            <el-form-item label="户型:" label-width="50px" prop="msg_hx">
              <el-input v-model="ruleForm.msg_hx" style="width: 170px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="案例主图:" prop="masterMap">
        <el-upload
          class="avatar-uploader"
          action=""
          :show-file-list="false"
          :auto-upload="false"
          :on-change="handPreviewMasterMap"
        >
          <img
            v-if="ruleForm.masterMap != ''"
            :src="ruleForm.masterMap"
            style="width: 148px; height: 148px; font-size: 0; object-fit: cover"
          />
          <i v-else class="el-icon-plus one" />
        </el-upload>
      </el-form-item>
      <el-form-item class="pictrueForm" label="案例图片:" prop="picture">
        <span style="color: red; display: block">
          注意！推荐尺寸不低于尺寸为800*600 PX , PNG / JPG格式 , 大小不超过10Mb
        </span>
        <div
          v-for="(item, index) in ruleForm.picture"
          :key="index"
          style="position: relative; display: inline-block !important"
        >
          <div style="width: 150px; height: 150px; display: inline-block; margin-right: 15px">
            <img
              :class="index === mainIndex ? 'picImgSelect' : 'picImg'"
              :src="item.url"
              style="object-fit: cover"
              @mouseout="showCover(index, false)"
              @mouseenter="showCover(index, true)"
            />
            <div
              v-if="item.show"
              @mouseleave="showCover(index, false)"
              @mousemove="showCover(index, true)"
              class="picMoveDiv"
            >
              <i class="el-icon-zoom-in" @click="showDialogImg(index)" style="margin-right: 10px" />
              <i
                class="el-icon-delete"
                @click="handleRemove(item, index)"
                style="margin-left: 10px"
              />
            </div>
            <!-- <div style="text-align: center">
                            <el-button style="margin-bottom: 10px" @click="mainIndex = index" type="text">设为主图
                            </el-button>
                        </div> -->
          </div>
          <el-dialog
            :visible.sync="item.showDialog"
            width="1200px"
            top="25px"
            @close="
              tagindx = '';
              item.showDialog = false;
            "
          >
            <el-row style="height: 650px; padding: 0 25px">
              <span style="position: relative; display: inline-block; width: 800px">
                <img
                  @mousemove="touchmove"
                  style="-webkit-user-drag: none; width: 800px; object-fit: cover"
                  :src="item.url"
                  alt=""
                />
                <span v-for="(tt, ind) in item.label" :key="ind">
                  <span
                    @mousedown="touchstart1($event, ind)"
                    :style="{
                      position: 'absolute',
                      diaplay: 'inline-block',
                      top: tt.top,
                      left: tt.left,
                    }"
                  >
                    <div class="chat">
                      <div class="triangle"></div>
                      <div class="fill"></div>
                      {{ tt.name }}
                    </div>
                  </span>
                </span>
              </span>
              <span
                style="display: inline-block; margin-left: 15px; width: 290px; position: absolute"
              >
                <div style="text-align: right; margin-bottom: 15px">
                  <el-button @click="addlabel()" type="primary">新增标签</el-button>
                </div>
                <span>
                  <el-table :show-header="false" :data="item.label" stripe style="width: 100%">
                    <el-table-column prop="name" width="120" label="name">
                      <template #default="scope">
                        <el-input v-model="scope.row.name" style="width: 100px" />
                      </template>
                    </el-table-column>
                    <el-table-column align="right" label="Address">
                      <template #default="scope">
                        <el-button type="text" @click="delLabel(scope)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </span>
              </span>
            </el-row>
          </el-dialog>
        </div>
        <el-row style="display: block">
          <el-upload
            multiple
            ref="picUload"
            :limit="10"
            action=""
            :show-file-list="false"
            list-type="picture-card"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG,.max,.MAX,.su,.SU"
            :file-list="ruleForm.picture"
            :auto-upload="false"
            :on-remove="handleRemove2"
            :on-change="handPreview"
          >
            <i class="el-icon-plus" />
          </el-upload>
        </el-row>
        <el-dialog
          top="30px"
          width="80%"
          :visible="isShowPictureCropperDialog"
          title="图片裁剪"
          :close-on-click-modal="false"
          v-model="isShowPictureCropperDialog"
          :show-close="false"
          append-to-body
          v-if="isShowPictureCropperDialog"
        >
          <div class="content-wrap">
            <div class="clipping-img" height="600px">
              <section class="edit-cropper-area" v-if="isShowPictureCropperDialog">
                <div class="image-cropper" style="width: auto; height: 600px">
                  <VueCropper
                    ref="imageCropper"
                    :img="option.img"
                    :outputSize="option.outputSize"
                    :outputType="option.outputType"
                    :info="option.info"
                    :canScale="option.canScale"
                    :autoCrop="option.autoCrop"
                    :autoCropWidth="option.autoCropWidth"
                    :autoCropHeight="option.autoCropHeight"
                    :fixed="option.fixed"
                    :fixedNumber="option.fixedNumber"
                    :full="option.full"
                    :fixedBox="option.fixedBox"
                    :canMove="option.canMove"
                    :canMoveBox="option.canMoveBox"
                    :original="option.original"
                    :centerBox="option.centerBox"
                    :high="option.high"
                    :infoTrue="option.infoTrue"
                    :maxImgSize="option.maxImgSize"
                    :enlarge="option.enlarge"
                  ></VueCropper>
                </div>
              </section>
            </div>
            <div class="footer-wrap">
              <div class="size">尺寸为800*600px或同比例图片</div>
              <div class="footer-btn">
                <el-button type="primary" @click="handleArtifactsPictureSaveCutOut()">
                  确认
                </el-button>
                <el-button @click="closeCropperDialog(1)">取 消</el-button>
              </div>
            </div>
          </div>
        </el-dialog>
      </el-form-item>
      <el-form-item label="案例3D图片:" prop="picture_3d">
        <div style="color: red">注意！推荐尺寸不低于3000*1500（2:1渲染图.jpg格式）</div>
        <el-row>
          <el-upload
            multiple
            ref="uploadpic3d"
            :limit="10"
            action=""
            list-type="picture-card"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG,.max,.MAX,.su,.SU"
            :file-list="ruleForm.picture_3d"
            :auto-upload="false"
            :on-preview="handlePictureCardPreview"
            :on-change="handPreview1"
            :on-remove="handleRemove1"
          >
            <i class="el-icon-plus" />
          </el-upload>
        </el-row>
        <el-dialog v-model="dialogVisible">
          <img style="width: 100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item style="margin-top: 35px; padding-bottom: 25px">
        <el-button style="width: 100px" type="primary" @click="submit('ruleForm')">确认</el-button>
        <el-button style="width: 100px" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import E from 'wangeditor';
import {
  addCase,
  getStyle,
  detailDepart,
  editDepart,
  getAttributesData,
  // getuploadspace,
} from '@/common/list.js';

import { VueCropper } from 'vue-cropper';
import 'vue-cropper/next/dist/index.css';
// import '@/assets/jquery/contextmenu.css'
// import $ from "jquery";
// import '@/assets/jquery.image-label.css'
// import '@/assets/jquery.image-label.js'
// import '@sub/imageLabel/contextMenu.js'

export default {
  name: 'EditCase',
  components: {
    VueCropper,
  },
  props: {
    caseId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // 富文本数据
      content: '',
      editor: '',
      brandList: [],
      // 裁剪图片配置项
      option: {
        img: '', // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式(jpeg || png ||webp)
        info: true, // 裁剪框的大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: '', // 默认生成截图框宽度
        autoCropHeight: '', // 默认生成截图框高度
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例,设置了autoCropWidth、autoCropHeight后不生效
        full: false, // 是否输出原图比例的截图
        fixedBox: true, // 固定截图框大小
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        high: true, // 是否按照设备的dpr 输出等比例图片
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        maxImgSize: 3840, // 限制图片最大宽度和高度
        enlarge: 2, // 图片根据截图框输出比例倍数
      },
      rules: {
        title: [{ required: true, message: '请输入案例名称', trigger: 'blur' }],
        // space_id: [
        //   { required: true, message: "请选择云空间", trigger: "change" },
        // ],
        case_style: [{ required: true, message: '请选择案例风格', trigger: 'change' }],
        // introduce: [{ required: true, message: '请输入案例介绍', trigger: 'blur' }],
        richText: [{ required: true, message: '请输入案例介绍', trigger: 'blur' }],
        picture: [{ required: true, message: '请上传案例图片', trigger: 'blur' }],
        masterMap: [{ required: true, message: '请上传案例主图', trigger: 'blur' }],
        sort: [
          {
            required: true,
            message: '请输入数字类型展示排序',
            trigger: 'blur',
          },
          { type: 'number', message: '请输入数字类型', trigger: 'change' },
        ],
      },
      isShowPictureCropperDialog: false,
      // isShowPicture3dCropperDialog:false,
      imgArr: [
        {
          src: require('../../../assets/a.png'),
        },
        {
          src: require('../../../assets/b.png'),
        },
      ],
      position: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
      top: '0px',
      left: '0px',
      top1: '0px',
      left1: '0px',
      value: '我是标签',
      styleArray: [],
      MyCloudList: [],
      ruleForm: {
        id: '',
        title: '',
        sort: '',
        case_style: '',
        case_label: [],
        // introduce: '',
        richText: '',
        msg_xq: '',
        msg_zxlx: '',
        msg_mj: '',
        msg_wgsj: '',
        msg_hx: '',
        picture: [],
        picture_3d: [],
        // BrandID: "",
        // space_id: "",
        // 主图
        masterMap: '',
        masterMapData: null,
      },
      mainIndex: '',
      inputVisible: false,
      inputValue: '',
      dialogImageUrl: '',
      dialogVisible: false,
      tagIndex: '',
      del_labels: [],
      update_labels: [],
      del_file: [],
      del_file_3d: [],
      beforePicArr: [],
      id: '',
      loading: false,
      getuploadspaceData: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  methods: {
    getAttributesData() {
      getAttributesData().then((res) => {
        this.brandList = res.result.brand_list;
      });
    },
    showDialogImg(index) {
      // console.log(index);
      // console.log(this.ruleForm.picture[index].showDialog);
      this.tagIndex = index;
      this.ruleForm.picture[index].showDialog = true;
      // console.log(this.ruleForm.picture[index].showDialog);
    },
    delLabel(scope) {
      if (scope.row.case_file_id) {
        this.del_labels.push({
          case_file_id: scope.row.case_file_id,
          label_key: scope.row.label_key,
        });
      }
      this.ruleForm.picture[this.tagIndex].label.splice(scope.$index, 1);
    },
    addlabel() {
      this.ruleForm.picture[this.tagIndex].label.push({
        name: '标签',
        left: '0px',
        top: '0px',
      });
    },
    showCover(index, val) {
      this.ruleForm.picture[index].show = val;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    // mousedown(e) {
    //   let odiv = e.target; //获取目标元素
    //   //算出鼠标相对元素的位置
    //   let disX = e.clientX - odiv.offsetLeft;
    //   let disY = e.clientY - odiv.offsetTop;
    //   // console.log(disX,disY);
    //   // document.onmousemove = (e)=>{       //鼠标按下并移动的事件
    //   //     //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
    //   //     let left = e.clientX - disX;
    //   //     let top = e.clientY - disY;

    //   //     //绑定元素位置到positionX和positionY上面
    //   //     this.positionX = top;
    //   //     this.positionY = left;

    //   //     //移动当前元素
    //   //     odiv.style.left = left + 'px';
    //   //     odiv.style.top = top + 'px';
    //   // };
    //   // document.onmouseup = (e) => {
    //   //     document.onmousemove = null;
    //   //     document.onmouseup = null;
    //   // };
    // },
    closeCropperDialog(type) {
      if (type == 1) {
        this.$refs.picUload.uploadFiles.splice(this.$refs.picUload.uploadFiles.length - 1, 1);
        this.isShowPictureCropperDialog = false;
      } else {
        // this.$refs.uploadpic3d.uploadFiles.splice(this.$refs.uploadpic3d.uploadFiles.length - 1, 1);
        // this.isShowPicture3dCropperDialog = false;
      }
    },
    handleArtifactsPictureSaveCutOut() {
      this.$refs.imageCropper.getCropBlob((data) => {
        let files = new File([data], this.fileType.name, {
          type: data.type,
        });
        let url = window.URL.createObjectURL(files);
        this.ruleForm.picture.push({
          name: this.fileType.name,
          url: url,
          raw: files,
          label: [],
          show: true,
          showDialog: false,
        });
        this.isShowPictureCropperDialog = false;
      });
    },
    // handleArtifactsPictureSaveCutOut1() {
    //     this.$refs.imageCropper1.getCropBlob(data => {
    //         let files = new File([data], this.fileType.name, {
    //             type: data.type
    //         });
    //         let url = window.URL.createObjectURL(files);
    //         this.ruleForm.picture_3d.push({ name: this.fileType.name, url: url, raw: files });
    //         // this.isShowPicture3dCropperDialog = false;
    //     });
    // },
    triggerRef() {
      return this.position;
    },
    touchstart(e) {
      this.top1 = e.offsetY + 'px';
      this.left1 = e.offsetX + 'px';
    },
    touchstart1(e, ind) {
      document.onmousemove = () => {
        //鼠标按下并移动的事件
        this.ruleForm.picture[this.tagIndex].label[ind].left = this.left1;
        this.ruleForm.picture[this.tagIndex].label[ind].top = this.top1;
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    // 当鼠标移动时触发
    touchmove(e) {
      // console.log(e.path[0].offsetWidth,e.path[0].offsetHeight,e.offsetX,e.offsetY);
      this.top1 = e.offsetY + 'px';
      this.left1 = e.offsetX + 'px';
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let dt = new FormData();
          // dt.append("BrandID", this.ruleForm.BrandID); //品牌ID
          // dt.append("space_id", this.ruleForm.space_id); //云空间
          dt.append('main_image', this.ruleForm.masterMapData); //品牌主图
          dt.append('title', this.ruleForm.title);
          dt.append('sort', this.ruleForm.sort);
          dt.append('case_style', this.ruleForm.case_style);
          for (var z = 0; z < this.ruleForm.case_label.length; z++) {
            dt.append('case_label[]', this.ruleForm.case_label[z]);
          }
          // dt.append('introduce', this.ruleForm.introduce);
          dt.append('introduce', this.ruleForm.richText);
          dt.append('msg_xq', this.ruleForm.msg_xq);
          dt.append('msg_zxlx', this.ruleForm.msg_zxlx);
          dt.append('msg_mj', this.ruleForm.msg_mj);
          dt.append('msg_wgsj', this.ruleForm.msg_wgsj);
          dt.append('msg_hx', this.ruleForm.msg_hx);
          for (var i = 0; i < this.ruleForm.picture.length; i++) {
            if (this.ruleForm.picture[i].raw) {
              dt.append('picture[]', this.ruleForm.picture[i].raw);
            }
            dt.append('tagData[' + i + '][key]', i);
            let pictureID = this.ruleForm.picture[i].id;
            if (pictureID) {
              dt.append('tagData[' + i + '][case_file_id]', pictureID);
            }
            // console.log(this.ruleForm.picture[i]);
            if (this.ruleForm.picture[i].label) {
              for (var l = 0; l < this.ruleForm.picture[i].label.length; l++) {
                if (
                  this.ruleForm.picture[i].label[l].label_key ||
                  this.ruleForm.picture[i].label[l].label_key == 0
                ) {
                  dt.append(
                    'tagData[' + i + '][label][' + l + '][name]',
                    this.ruleForm.picture[i].label[l].name,
                  );
                  dt.append(
                    'tagData[' + i + '][label][' + l + '][left]',
                    this.ruleForm.picture[i].label[l].left,
                  );
                  dt.append(
                    'tagData[' + i + '][label][' + l + '][top]',
                    this.ruleForm.picture[i].label[l].top,
                  );
                  dt.append(
                    'tagData[' + i + '][label][' + l + '][label_key]',
                    this.ruleForm.picture[i].label[l].label_key,
                  );
                  dt.append(
                    'tagData[' + i + '][label][' + l + '][case_file_id]',
                    this.ruleForm.picture[i].label[l].case_file_id,
                  );
                } else {
                  dt.append(
                    'tagData[' + i + '][label][' + l + '][name]',
                    this.ruleForm.picture[i].label[l].name,
                  );
                  dt.append(
                    'tagData[' + i + '][label][' + l + '][left]',
                    this.ruleForm.picture[i].label[l].left,
                  );
                  dt.append(
                    'tagData[' + i + '][label][' + l + '][top]',
                    this.ruleForm.picture[i].label[l].top,
                  );
                }
              }
            }
            if (this.mainIndex === i) {
              if (this.ruleForm.picture[i].raw) {
                dt.append('z_picture', this.ruleForm.picture[i].name);
              } else {
                dt.append('z_picture', this.ruleForm.picture[i].id);
              }
            }
          }
          for (var j = 0; j < this.ruleForm.picture_3d.length; j++) {
            if (this.ruleForm.picture_3d[j].raw) {
              dt.append('picture_3d[]', this.ruleForm.picture_3d[j].raw);
            }
          }
          if (this.id) {
            dt.append('id', this.ruleForm.id);
            // dt.append("id", this.id);
            for (var p = 0; p < this.del_file.length; p++) {
              dt.append('del_file[' + p + '][id]', this.del_file[p].id);
              dt.append('del_file[' + p + '][url]', this.del_file[p].url);
            }
            for (var p3d = 0; p3d < this.del_file_3d.length; p3d++) {
              dt.append('del_file_3d[' + p3d + '][url]', this.del_file_3d[p3d].url);
            }
            for (var de = 0; de < this.del_labels.length; de++) {
              dt.append('del_labels[' + de + '][label_key]', this.del_labels[de].label_key);
              dt.append('del_labels[' + de + '][case_file_id]', this.del_labels[de].case_file_id);
            }
            for (let [a, b] of dt.entries()) {
              console.log(a + '=>' + b);
            }
            editDepart(dt).then((res) => {
              this.$message.success(res.msg);
              this.$refs.ruleForm.resetFields();
              this.$emit('closeDialod');
              this.$router.back();
            });
          } else {
            // for (let [a, b] of dt.entries()) {
            //     console.log(a + '=>' + b);
            // }
            dt.append('space_id', +this.$route.query.spaceId);
            addCase(dt).then((res) => {
              this.$message.success(res.msg);
              this.$refs.ruleForm.resetFields();
              this.$emit('closeDialod');
            });
          }
        }
      });
    },
    handleClose(tag) {
      this.ruleForm.case_label.splice(this.ruleForm.case_label.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.InputRef.focus();
      });
    },
    handleInputConfirm() {
      if (this.inputValue) {
        this.ruleForm.case_label.push(this.inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    selectMain(file) {
      for (var o = 0; o < this.ruleForm.picture.length; o++) {
        if (file.name == this.ruleForm.picture[o].name) {
          this.ruleForm.picture[o].mainPic = true;
        } else {
          this.ruleForm.picture[o].mainPic = false;
        }
      }
    },

    handleRemove(file, index) {
      if (!file.raw) {
        this.del_file.push({ url: file.url, id: file.id });
      }
      if (file.label && file.label.length) {
        for (var q = 0; q < file.label.length; q++) {
          this.del_labels.push({
            case_file_id: file.label[q].case_file_id,
            label_key: file.label[q].label_key,
          });
        }
      }
      // this.$refs.picUload.handleRemove(file, this.ruleForm.picture);
      this.ruleForm.picture.splice(index, 1);
    },
    handPreview(file) {
      //fileList
      const isType = file.raw.type === 'image/jpeg' || 'image/png';
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isType) {
        this.$message.error('上传图片只能是 jpg/png 格式!');
        this.ruleForm.picture.pop();
        return;
      } else if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
        this.ruleForm.picture.pop();
        return;
      } else {
        // 保存图片基本信息
        this.fileType = file.raw;
        // console.log(file.raw);
        // 打开裁剪弹窗
        let reader = new FileReader(); // 创建文件读取对象
        reader.onload = (e) => {
          let imgUrl;
          // console.log(typeof e.target.result === "object");
          if (typeof e.target.result === 'object') {
            // 把Array Buffer转化为blob 如果是base64不需要
            imgUrl = window.URL.createObjectURL(new Blob([e.target.result]));
          } else {
            imgUrl = e.target.result;
          }
          this.option.img = imgUrl;
          this.isShowPictureCropperDialog = true;
        };
        // 转化为blob
        reader.readAsArrayBuffer(file.raw);
        // 重新设置option属性
        this.option.autoCropWidth = 800;
        this.option.autoCropHeight = 600;
        this.option.enlarge = 2;
      }
    },
    // 主图
    handPreviewMasterMap(file, fileList) {
      console.log(fileList);
      this.ruleForm.masterMap = URL.createObjectURL(file.raw);
      this.ruleForm.masterMapData = file.raw;
    },
    getBase64Img(params) {
      if (params && params.url) {
        // var that = this;
        var image = new Image();
        image.src = params.url + '?' + Math.random();
        image.crossOrigin = 'anonymous';
        image.onload = function () {
          var canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          var ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, image.width, image.height);
          var ext = image.src.substring(image.src.lastIndexOf('.') + 1).toLowerCase();
          var dataURL = canvas.toDataURL('image/' + ext); //这就是转化后的图片地址
          // if (params.callback) {
          //     if (params.data) {
          //         that[params.callback](dataURL, params.data);
          //     } else {
          //         that[params.callback](dataURL);
          //     }
          // };
          return dataURL;
        };
      }
    },
    handleRemove1(file, fileList) {
      if (!file.raw) {
        this.del_file_3d.push({ url: file.url });
      }
      this.ruleForm.picture_3d = fileList;
      this.$refs['ruleForm'].validateField('picture_3d');
    },
    handleRemove2(file, fileList) {
      this.ruleForm.picture = fileList;
      this.$refs['ruleForm'].validateField('picture_3d');
    },
    handPreview1(file, fileList) {
      const isType = file.raw.type === 'image/jpg' || file.raw.type === 'image/jpeg';
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isType) {
        this.$message.error('上传图片只能是 jpg 格式!');
        fileList.splice(fileList.length - 1, 1);
        // this.ruleForm.picture_3d.pop();
        return;
      } else if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
        fileList.splice(fileList.length - 1, 1);
        // this.ruleForm.picture_3d.pop();
        return;
      } else {
        return new Promise(function (resolve, reject) {
          // let width = "";
          // let height = "";
          let _URL = window.URL || window.webkitURL;
          let img = new Image();
          img.onload = function () {
            // width = img.width;
            // height = img.height;
            let valid = img.width / img.height === 2;
            valid ? resolve() : reject();
          };
          img.src = _URL.createObjectURL(file.raw);
        }).then(
          () => {
            this.ruleForm.picture_3d.push(file);
            return file;
          },
          () => {
            this.$message.warning(`上传图片尺寸只能是2:1渲染图`);
            fileList.splice(fileList.length - 1, 1);
          },
        );
      }
    },
    //清除编辑器缓存 使用ref调用这个方法
    clearFun() {
      this.editor.txt.clear();
    },
    btn() {
      // console.log(this.editor)
      // console.log(this.editor.customConfig)
      // 监听实时富文本输入内容
      // this.editor.config.onchange = (html) => {
      //     console.log(html);
      // }
      // this.editor.config.onchange = function (html) {
      //     // 第二步，监控变化，同步更新到 textarea
      //     // $text1.val(html)
      //     console.log(html);
      // }
    },
    //初始化
    initE() {
      this.editor = new E(this.$refs.editor); //使用这个初始化,可以在同一界面创建多个编辑器
      // console.log(this.editor);
      // this.editor = new E('#e') //用这个。到用两个编辑框的时候会出现 ：初始化节点已存在编辑器实例，无法重复创建编辑
      let that = this;
      this.editor.config.lineHeights = [
        '1',
        '1.25',
        '1.5',
        '1.75',
        '2',
        '2.25',
        '2.5',
        '2.75',
        '3',
      ]; //行高
      this.editor.config.fontSizes = {
        'x-small': { name: '10px', value: '1' },
        small: { name: '13px', value: '2' },
        normal: { name: '16px', value: '3' },
        large: { name: '18px', value: '4' },
        'x-large': { name: '24px', value: '5' },
        'xx-large': { name: '32px', value: '6' },
        'xxx-large': { name: '48px', value: '7' },
      }; //字号

      // this.editor.config.pasteFilterStyle = false
      this.editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        // 'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'lineHeight', //行高
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        'undo', // 撤销
        'redo', // 重复
        // 'video',//视频
      ];
      this.editor.config.uploadImgMaxSize = 50 * 1024 * 1024; //限制图片上传大小 50M
      this.editor.config.showFullScreen = false; //全屏按钮
      this.editor.config.showLinkImg = false; //隐藏网络图片
      this.editor.config.showLinkVideo = false; //隐藏网络视频
      this.editor.config.uploadImgShowBase64 = true;
      // this.editor.config.uploadImgServer = "/api/casemanage/case_upload"; //上传图片地址
      // this.editor.config.uploadImgParams = {
      //   space_id: 23,
      // };

      // this.editor.config.uploadImgHeaders = {
      //   token: window.sessionStorage.getItem("token"),
      //   ContentType: "multipart/form-data",
      // };

      this.editor.config.onchange = function (html) {
        // 第二步，监控变化，同步更新到 textarea
        // $text1.val(html)
        // console.log(html);
        that.ruleForm.richText = html;
      };
      this.editor.create();
      //判断model是否传值，如果有就添加，没有就清除  ！！一定要在editor.create()之后使用
      if (this.ruleForm.richText) {
        this.editor.txt.html(this.ruleForm.richText);
      } else {
        this.editor.txt.clear();
      }
    },
  },
  mounted() {
    this.initE();
    this.getAttributesData();
    getStyle().then((res) => {
      this.styleArray = res.result;
    });
    // 获取空间列表
    // getuploadspace().then((res) => {
    //   this.MyCloudList = res.result;
    //   console.log(this.MyCloudList);
    // });
    // 获取案例详情
    this.$nextTick(() => {
      if (this.id) {
        let dt = {
          id: this.id,
        };
        detailDepart(dt).then((res) => {
          this.ruleForm.id = res.result.id;
          this.ruleForm.masterMap = res.result.main_image;
          // this.ruleForm.masterMapData = res.result.main_image;
          this.ruleForm.title = res.result.title;
          this.ruleForm.sort = res.result.sort;
          this.ruleForm.case_style = res.result.case_style;
          // this.ruleForm.BrandID = res.result.brand_id;
          this.ruleForm.case_label = res.result.case_label;
          // this.ruleForm.introduce = res.result.introduce;
          this.ruleForm.richText = res.result.introduce;
          this.editor.txt.html(this.ruleForm.richText);
          this.ruleForm.msg_xq = res.result.msg_xq;
          this.ruleForm.msg_zxlx = res.result.msg_zxlx;
          this.ruleForm.msg_mj = res.result.msg_mj;
          this.ruleForm.msg_wgsj = res.result.msg_wgsj;
          this.ruleForm.msg_hx = res.result.msg_hx;
          let picture = res.result.picture;
          this.ruleForm.picture = [];
          for (var p in picture) {
            this.ruleForm.picture.push({
              url: picture[p].url,
              id: picture[p].id,
              label: picture[p].label.labels,
              show: true,
              showDialog: false,
            });
            if (picture[p].select == 1) {
              this.mainIndex = parseInt(p);
            }
          }
          let picture_3d = res.result.picture_3d;
          for (var i in picture_3d) {
            this.ruleForm.picture_3d.push({ url: picture_3d[i] });
          }
        });
      }
    });
  },
};
</script>
<style scoped>
.edit_case .pictrueForm .el-upload-list--picture-card .el-upload-list__item {
  display: none;
}

.edit_case {
  overflow-y: scroll;
  height: calc(100vh - 200px);
}

.picMoveDiv {
  position: absolute;
  line-height: 150px;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  border-radius: 5px;
  top: 0px;
  left: 0px;
  height: 150px;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.picImg {
  border-radius: 5px;
  width: 148px;
  height: 148px;
  border: 1px dashed var(--el-border-color-base);
}

.picImgSelect {
  border-radius: 5px;
  width: 148px;
  height: 148px;
  border: 1px solid #409eff;
}

.el-alert {
  margin: 15px 0 0;
  line-height: 20px;
}

.el-alert:first-child {
  margin: 0;
}

.chat {
  position: relative;
  /* max-width: 260px; */
  padding: 0px 5px;
  border: 1px solid #303133;
  background-color: #303133;
  color: #ffffff;
  border-radius: 5px;
  word-break: break-all;
  cursor: pointer;
  user-select: none;
}

.fill {
  -webkit-user-drag: none;
  user-select: none;
}

.triangle,
.triangle_two {
  position: absolute;
  top: 6px;
  border-width: 10px;
  border-style: solid;
}

.triangle {
  left: -20px;
  border-color: transparent #303133 transparent transparent;
  -webkit-user-drag: none;
  user-select: none;
}

.triangle_two {
  right: -20px;
  border-color: transparent transparent transparent #ccc;
}

.avatar-uploader,
.el-upload {
  width: 148px;
  height: 148px;
}

.one {
  width: 148px;
  height: 148px;
  font-size: 24px;
  line-height: 148px;
  background-color: #fbfdff;
  color: #8c939d;
}

::v-deep .w-e-toolbar {
  z-index: 2 !important;
}

.w-e-menu {
  z-index: 2 !important;
}

::v-deep .w-e-text-container {
  z-index: 1 !important;
}
</style>
